<template>
  <div class="related-users-data col-xs-12 list group">
    <label for="related-users-data">Osoby powiązane ze zleceniem:</label>
    <div>
      <div class="list-group-item list-group-item-overflowed">
        <label>Asystent: </label> {{ assistant ? assistant.fullname : "b.d." }} <br>
        <label>Adres email:</label>
        {{ assistant && assistant.email ? assistant.email : "b.d." }} <br>
        <label>Numer telefonu:</label>
        {{ assistant && assistant.phone ? assistant.phone : "b.d." }} <br>
      </div>
      <div class="list-group-item list-group-item-overflowed">
        <label>Analityk: </label> {{ analyst ? analyst.fullname : "b.d." }} <br>
        <label>Numer telefonu:</label>
        {{ analyst && analyst.phone ? analyst.phone : "b.d." }} <br>
      </div>
      <div class="list-group-item list-group-item-overflowed">
        <label>Rzeczoznawca: </label>
        {{ appraiser ? appraiser.fullname : "b.d." }} <br>
        <label>Adres email:</label>
        {{ appraiser && appraiser.email ? appraiser.email : "b.d." }} <br>
        <label>Numer telefonu:</label>
        {{ appraiser && appraiser.phone ? appraiser.phone : "b.d." }} <br>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RelatedUsersAppraisal",
  props: {
    relatedUsersDataAppraisal: null,
  },
  methods: {
    findUserWithRole(role) {
      return this.relatedUsersDataAppraisal.find(
        (relatedUser) => relatedUser.role === role
      );
    },
  },
  computed: {
    assistant() {
      return this.findUserWithRole("ROLE_ASYSTENT_BWN");
    },
    analyst() {
      return this.findUserWithRole("ROLE_ANALITYK");
    },
    appraiser() {
      return this.findUserWithRole("ROLE_RZECZOZNAWCA_ZEWN");
    },
  },
};
</script>
<style>
</style>