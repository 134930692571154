<script>
import Schema from './Schema.js'

export default {
  namne: 'form-schema',
  mixins: [
    Schema
  ],
  props: {
    service: {
      type: String,
      required: true
    }
  }
}
</script>
