<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
      v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_customer'})">
        <CustomerData :customer-data="customerData"></CustomerData>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
      v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})">
    <div  v-if="$auth.isAllowed({service: this.service, action: 'get_task_related_users_appraisal'})">
        <RelatedUsersAppraisal :related-users-data-appraisal="relatedUsersDataAppraisal"  />
      </div>
        <CustomerWillDeliverDocumentation
          v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})"
          :credit-purpose-data="creditPurposeData"></CustomerWillDeliverDocumentation>
        <CreditPurpose v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})"
          :credit-purpose-data="creditPurposeData"></CreditPurpose>
        <div class="col-xs-12">
          <label>Typy nieruchomości:</label>
          <RealEstateKinds  
            v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})" 
            :credit-purposeappraisal-task="task"></RealEstateKinds>
        </div>
        <DeliveryMethods v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_credit_purpose'})" :credit-purpose-data="creditPurposeData"/>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_real_estate'})">
        <RealEstateAddressesAppraisal :real-estate-data="realEstateData"></RealEstateAddressesAppraisal>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"
          v-if="$auth.isAllowed({service: this.service, action: 'get_summary_appraisal_invoices'}) && appraisalInvoicesData !== null">
        <label for="address-purpose-data">FV zlecenia:</label>
        <InvoiceAppraisal :appraisal-invoice="appraisalInvoicesData" :appraisal-invoice-index="'0'" ></InvoiceAppraisal>
      </div>
    </div>
  </div>
</template>

<script>
import summaryRequests from './summaryRequests.js'
import CreditPurpose from './components/CreditPurpose.vue'
import CustomerWillDeliverDocumentation from './components/CustomerWillDeliverDocumentation.vue'
import CustomerData from './components/CustomerData.vue'
import RealEstateAddressesAppraisal
  from './components/RealEstateAddressesAppraisal.vue'
import DeliveryMethods from './components/DeliveryMethods.vue'
import InvoiceAppraisal from './components/InvoiceAppraisal.vue'
import RealEstateKinds from './components/RealEstateKinds.vue'
import RelatedUsersAppraisal from './components/RelatedUsersAppraisal.vue'

export default {
  name: 'Summary',
  components: {
    RealEstateKinds,
    InvoiceAppraisal,
    DeliveryMethods,
    RealEstateAddressesAppraisal,
    CustomerData,
    CustomerWillDeliverDocumentation,
    CreditPurpose,
    RelatedUsersAppraisal,
  },
  mixins: [summaryRequests],
  props: {
    taskId: {default: () => null},
    taskGroup: {},
    service: {type: String, default: () => ''},
    task: {
      required: true
    },
    summaryTabOpened: {type: Boolean},
    dataToShow: {type: Array},
  },
  watch: {
    summaryTabOpened: function (newValue, value) {
      if (newValue) {
        this.loadData()
      }
    }
  },
  data () {
    return {
      customerData: null,
      creditPurposeData: null,
      realEstateData: null,
      appraisalInvoicesData: null,
      relatedUsersDataAppraisal: []
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    loadData () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_customer'
      }, () => this.getCustomerData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_credit_purpose'
      }, () => this.getCreditPurposeData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_real_estate'
      }, () => this.getRealEstateData(this.taskGroup))

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_invoices'
      }, () => this.getAppraisalInvoicesData()),

      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_task_related_users_appraisal'
      }, () => this.getRelatedUsersDataAppraisal(this.taskGroup))
    },
    handleInvoicesUpdated () {
      this.$handleCheckAndCallMethod({
        service: this.service,
        action: 'get_summary_appraisal_invoices'
      }, () => this.getAppraisalInvoicesData())
    }
  }
}
</script>

<style scoped>
</style>
